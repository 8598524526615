import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  HeaderLintingPages,
  Spinner,
  ListView,
  CardListVertical,
  GradientBanner,
  Seo,
} from "../../components"
import { useContentFilters } from "../../hooks/use-content-filters"
import { getOptionsSelect } from "../../helpers"
import "./insights-styles.scss"

const InsightsView = ({
  loading,
  data,
  onChangeAllTypes,
  totalPages,
  totalPosts,
  onHandleNextPage,
  pageSelect,
}) => {
  const { t } = useTranslation()
  const [options, setOptions] = useState([])
  const { typeFilter } = useContentFilters()

  useEffect(() => {
    const selectOptions = [
      {
        options: getOptionsSelect(typeFilter, t("news.all-types")),
        placeholder: t("news.all-types"),
        onChange: onChangeAllTypes,
      },
    ]
    setOptions(selectOptions)
  }, [typeFilter])
  return (
    <div className="insights__container">
      <Seo title="Insights" />
      <div className="insights__box">
        <HeaderLintingPages
          title={t("insights.title")}
          selectOptions={options}
        />
        {loading ? (
          <Spinner loading={loading} />
        ) : (
          <ListView
            t={t}
            data={data}
            card={CardListVertical}
            classN="col-12 col-sm-4 col-md-4"
            totalPosts={totalPosts}
            onHandleNextPage={onHandleNextPage}
            totalPages={totalPages}
            pageSelect={pageSelect}
            itemsPerPage={10}
          />
        )}
      </div>
      <GradientBanner />
    </div>
  )
}

export default InsightsView
