import React, { useEffect, useState } from 'react'
import makeRequest from '../../helpers/make-request'
import InsightsView from './insights-view'
import { useTranslation } from 'react-i18next'

const InsightsDetailController = ({ slug }) => {
  const [loading, setLoading] = useState(true)
  const [dataInsights, setDataInsights] = useState([])
  const controller = new AbortController()

  const { i18n } = useTranslation();
  // catch with useEffect so the data will be contained
  const getDataInsightsDetail = async () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }

    setLoading(true)
    const fields = 'id,title,slug,acf,human_date,thumbnail,related,categories,featured_media'
    const headers = {
      'Content-Type': 'application/json',
    }


    // fetch the data with makerequest
    makeRequest({
      headers,
      endPoint: 'insights',
      signal: controller.signal,
      params: {
        slug,
        fields,
        lang: i18n.language
      },
    }).then((resp) => {
      setDataInsights(resp.data[0])
      setLoading(false)
    })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }
  useEffect(() => {
    getDataInsightsDetail()
  }, [])

  useEffect(() => {
    getDataInsightsDetail()
    return () => {
      controller.abort()
    }
  }, [slug])

  const viewProps = {
    data: dataInsights,
    loading,
  }
  return (
    <InsightsView {...viewProps}/>
  )
}

export default InsightsDetailController
